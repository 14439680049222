import { PERMISSION_ACTIONS, PERMISSION_SUBJECTS } from './permissionsUtils';
import { store } from '../../../services/redux/storeConfig/store';

export const arrangeUserSession = (userData) => {
    let response = {
        user_id:null,
        email:null,
        name:null,
        lastname:null,
        avatar:null,
        role_slug:null,
        role:null
    };
    if(userData){
        response.user_id = userData.id;
        response.email = userData.correo;
        response.avatar = userData.avatar;
        if(userData.perfil){
            response.name = userData.perfil.nombres
            response.lastname = userData.perfil.apellidos
        }
        if(userData.rol){
            response.role_slug = userData.rol.slug
            response.role = userData.rol.titulo
        }
    }

    return response;
}


export const defineRulesFor = (auth) => {
    const actionsToExclude = [PERMISSION_ACTIONS.recover.name];
    const permissions = [];
    let subjects = [];
    let actions = [];
    switch(auth.role_slug){
        case 'super':
            Object.keys(PERMISSION_SUBJECTS).forEach((subjectKey) => {
                const subject = PERMISSION_SUBJECTS[subjectKey].name;
                Object.keys(PERMISSION_ACTIONS).forEach((actionKey) => {
                    const action = PERMISSION_ACTIONS[actionKey].name;
                    permissions.push({
                        action:action,
                        subject:subject
                    })
                });
            });
            break;
        case 'admin':
            subjects = [];
            subjects.push(
                PERMISSION_SUBJECTS.home,
                PERMISSION_SUBJECTS.user,
                PERMISSION_SUBJECTS.imports,
                PERMISSION_SUBJECTS.employee,
                PERMISSION_SUBJECTS.client,
                PERMISSION_SUBJECTS.company,
                PERMISSION_SUBJECTS.payroll,
                PERMISSION_SUBJECTS.payrollConcept,
                PERMISSION_SUBJECTS.notifications
            );
            Object.keys(subjects).forEach((subjectKey) => {
                const subject = subjects[subjectKey].name;
                Object.keys(PERMISSION_ACTIONS).forEach((actionKey) => {
                    const action = PERMISSION_ACTIONS[actionKey].name;
                    !actionsToExclude.includes(action) && permissions.push({
                        action:action,
                        subject:subject
                    })
                });
            });
            break;
        case 'supervisor_nominas':
            permissions.push(
                {
                    subject: PERMISSION_SUBJECTS.home.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.notifications.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.payrollSendToAuthorized.name
                },
            );
            break;
        case 'ejecutivo_nomina':
            permissions.push(
                {
                    subject: PERMISSION_SUBJECTS.home.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.notifications.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.read.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.create.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.update.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.delete.name
                },
                {
                    subject: PERMISSION_SUBJECTS.payroll.name,
                    action: PERMISSION_ACTIONS.payrollSendToPendingAuthorize.name
                },
            );
            break;
        case 'admin_cliente':
            subjects = [];
            subjects.push(
                PERMISSION_SUBJECTS.home,
                PERMISSION_SUBJECTS.user,
                PERMISSION_SUBJECTS.employee,
                PERMISSION_SUBJECTS.payroll,
                PERMISSION_SUBJECTS.notifications
            );
            Object.keys(subjects).forEach((subjectKey) => {
                const subject = subjects[subjectKey].name;
                Object.keys(PERMISSION_ACTIONS).forEach((actionKey) => {
                    const action = PERMISSION_ACTIONS[actionKey].name;
                    !actionsToExclude.includes(action) && permissions.push({
                        action:action,
                        subject:subject
                    })
                });
            });
            break;
        case 'promotor':
            break;
    }
    return permissions;
}

export const canDo = (props = {}) => {
    let canDo = false;
    const {action, subject} = props;
    const permissions = store.getState().auth.session.data.permissions;
    if(permissions){
        canDo = permissions.filter((permission) => {return permission.action===action && permission.subject===subject}).length>0;
    }
    return canDo;
}
